import "./AccessWallet.scss";

import { MCNWallet } from "juneojs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EyesClose, EyesOpen } from "../../../assets/svg/svgIcons";
import { ArrowBack } from "../../../assets/svg/svgIcons";
import { Button } from "../../../mcn-react-ui";
import useAccountStore from "../../../stores/accountStore";
import FooterOnboarding from "../FooterOnboarding/FooterOnboarding";
import Root from "../Utils/Root/Root";
import SelectWrapper, {
  SelectOption,
} from "../Utils/SelectWrapper/SelectWrapper";
import MultiSigInput, { MultisigKeyData } from "./MultiSigInput/MultiSigInput";
import PhraseInput from "./PhraseInput/PhraseInput";

const AccessWallet = () => {
  const { setMasterWallet, setIsConnected } = useAccountStore();
  const [restoreMode, setRestoreMode] = useState("12 Words mnemonic phrase");
  const [phraseWords, setPhraseWords] = useState(
    restoreMode === "Private key"
      ? ""
      : Array(restoreMode === "12 Words mnemonic phrase" ? 12 : 24).fill(""),
  );

  // Multisig
  const [multisigKeyData, setMultisigKeyData] = useState<MultisigKeyData[]>([
    { value: "", showPassword: false, invalid: false },
  ]);

  const [showPassword, setShowPassword] = useState(false);
  const [onError, setOnError] = useState(false);

  const backToHome = useNavigate();
  const onBack = () => {
    backToHome("/");
  };

  // to change the number of input fields based on the restore mode
  useEffect(() => {
    setPhraseWords(
      Array(
        restoreMode === "12 Words mnemonic phrase"
          ? 12
          : restoreMode === "24 Words mnemonic phrase"
            ? 24
            : 1,
      ).fill(""),
    );
  }, [restoreMode]);

  const options: SelectOption[] = [
    { value: "12 Words mnemonic phrase", label: "12 Words mnemonic phrase" },
    { value: "24 Words mnemonic phrase", label: "24 Words mnemonic phrase" },
    { value: "Private key", label: "Private key" },
    { value: "Multisig", label: "Multisig" },
  ];

  const handlePhraseInputChange = (index: number, value: string) => {
    const words = value.split(" ");

    if (words.length > 1) {
      // If multiple words are pasted
      const updatedPhraseWords = [...phraseWords];
      for (
        let i = 0;
        i < words.length && index + i < updatedPhraseWords.length;
        i++
      ) {
        updatedPhraseWords[index + i] = words[i];
      }
      setPhraseWords(updatedPhraseWords);
    } else {
      // If only a single word is entered
      if (phraseWords instanceof Array) {
        setPhraseWords(
          phraseWords.map((word, i) => (i === index ? value : word)),
        );
      }
    }
  };

  const restoreWallet = () => {
    if (restoreMode === "Multisig") {
      const masterWallets: MCNWallet[] = [];
      const invalidKeysIndices: number[] = [];

      for (let i = 0; i < multisigKeyData.length; i++) {
        const keyData = multisigKeyData[i];
        try {
          if (keyData.value === "") {
            invalidKeysIndices.push(i);
          } else {
            masterWallets.push(MCNWallet.recover(keyData.value));
          }
        } catch (e) {
          invalidKeysIndices.push(i);
        }
      }

      if (invalidKeysIndices.length > 0) {
        setMultisigKeyData((currentMultisigKeyData) =>
          currentMultisigKeyData.map((kd, idx) => ({
            ...kd,
            invalid: invalidKeysIndices.includes(idx),
          })),
        );
        return;
      }

      if (masterWallets.length === 0) {
        return;
      }

      setOnError(false);
      setMasterWallet(masterWallets);
      setIsConnected(true);
      backToHome("/");
    } else {
      try {
        // Rejoin phraseWords into a single string if it's an array (12 or 24 words)
        const phrase =
          phraseWords instanceof Array ? phraseWords.join(" ") : phraseWords;

        const masterWallet: MCNWallet = MCNWallet.recover(phrase);

        setOnError(false);
        setMasterWallet([masterWallet]);
        setIsConnected(true);
        backToHome("/");
      } catch (e) {
        console.log(e);
        setOnError(true);
      }
    }
  };

  const handleChangeMode = (value: string) => {
    setRestoreMode(value);
    setOnError(false);
  };

  return (
    <Root>
      <div className="accessWalletPage">
        <div className="accessWalletTitle">
          <h1>Import a secret phrase</h1>
          <span>
            Paste or type in your Secret Phrase, which is usually a set of 12 or
            24 words.
          </span>
        </div>
        <div className="accessWalletPageDiv">
          <div className="overlay"></div>
          <div onClick={onBack} className="backArrow">
            <ArrowBack />
          </div>
          <SelectWrapper options={options} onChange={handleChangeMode} />
          <div className="phraseInputDiv">
            {restoreMode !== "Private key" &&
              restoreMode !== "Multisig" &&
              Array.from({
                length: restoreMode === "12 Words mnemonic phrase" ? 12 : 24,
              }).map((_, index) => (
                <PhraseInput
                  key={index}
                  index={index}
                  placeholder={`Word #${index + 1}`}
                  value={phraseWords[index]}
                  onChange={(index, value) => {
                    handlePhraseInputChange(index, value);
                    setOnError(false);
                  }}
                />
              ))}
          </div>
          {restoreMode === "Private key" && (
            <div className="privateKeyInput">
              <input
                type={showPassword ? "text" : "password"}
                placeholder={restoreMode}
                autoComplete="new-password"
                value={phraseWords}
                onChange={(event) => {
                  setPhraseWords(event.target.value);
                  setOnError(false);
                }}
                className="inputAccessWalletPageDiv"
              />
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="passwordtoggle"
              >
                {showPassword ? <EyesClose /> : <EyesOpen />}
              </button>
            </div>
          )}
          {restoreMode === "Multisig" && (
            <MultiSigInput
              multisigKeyData={multisigKeyData}
              setMultisigKeyData={setMultisigKeyData}
            />
          )}

          <div className="buttonAccessWalletPageDiv">
            <div className="errorAccessWalletPageDiv">
              {onError &&
                restoreMode !== "Private key" &&
                "Invalid key phrase. Please check that your phrase is formatted correctly."}
              {onError &&
                restoreMode === "Private key" &&
                "Invalid private key."}
            </div>

            <Button
              onClick={restoreWallet}
              label="Restore Wallet"
              width={"auto"}
              height={47}
              className="buttonAccessWallet"
              padding="14px 25px"
            />
          </div>
        </div>
      </div>
      <FooterOnboarding />
    </Root>
  );
};

export default AccessWallet;
