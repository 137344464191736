import "./MultiSigInput.scss";

import { MCNWallet } from "juneojs";
import toast from "react-hot-toast";

import {
  EyesClose,
  EyesOpen,
  GrAdd,
  GrClose,
} from "../../../../assets/svg/svgIcons";
import { Button } from "../../../../mcn-react-ui";
import useNetworkStore from "../../../../stores/networkStore";

export interface MultisigKeyData {
  value: string;
  showPassword: boolean;
  invalid: boolean;
  MCNWallet?: MCNWallet;
}
interface MultiSigInputProps {
  multisigKeyData: MultisigKeyData[];
  setMultisigKeyData: (multisigKeyData: MultisigKeyData[]) => void;
}

const MultiSigInput = ({
  multisigKeyData,
  setMultisigKeyData,
}: MultiSigInputProps) => {
  const { provider } = useNetworkStore();

  const handleKeyOrMnemonicChange = (index: number, value: string) => {
    const updatedMultisigKeyData = [...multisigKeyData];
    updatedMultisigKeyData[index].value = value;
    try {
      updatedMultisigKeyData[index].MCNWallet = MCNWallet.recover(value);
      updatedMultisigKeyData[index].invalid = false;
    } catch (e) {
      updatedMultisigKeyData[index].invalid = true;
      updatedMultisigKeyData[index].MCNWallet = undefined;
    }
    setMultisigKeyData(updatedMultisigKeyData);
  };

  const handleKeyOrMnemonicRemove = (index: number) => {
    // check if there is only one key or mnemonic
    if (multisigKeyData.length === 1) {
      toast.error("You need at least one key or mnemonic");
      return;
    }
    setMultisigKeyData(multisigKeyData.filter((_, i) => i !== index));
  };

  const handleAddKeyOrMnemonic = () => {
    setMultisigKeyData([
      ...multisigKeyData,
      { value: "", showPassword: false, invalid: false },
    ]);
  };

  const toggleShowPassword = (index: number) => {
    const updatedMultisigKeyData = [...multisigKeyData];
    updatedMultisigKeyData[index].showPassword =
      !updatedMultisigKeyData[index].showPassword;
    setMultisigKeyData(updatedMultisigKeyData);
  };

  return (
    <div className="multisigInput">
      {multisigKeyData.map((key, index) => (
        <div className="line" key={index}>
          <div className="lineContainer">
            <div className={`privateKeyInput ${key.invalid ? "invalid" : ""}`}>
              <input
                type={key.showPassword ? "text" : "password"}
                placeholder="Key or mnemonic"
                autoComplete="new-password"
                value={key.value}
                onChange={(event) =>
                  handleKeyOrMnemonicChange(index, event.target.value)
                }
                className="inputAccessWalletPageDiv"
              />
              <button
                onClick={() => toggleShowPassword(index)}
                className="passwordtoggle"
              >
                {key.showPassword ? <EyesClose /> : <EyesOpen />}
              </button>
            </div>

            <Button
              onClick={() => handleKeyOrMnemonicRemove(index)}
              icon={<GrClose />}
              style="transparent"
              width={30}
              height={30}
            />
          </div>
          <span className="addressPreviewContainer">
            <span className="addressPreview">
              {key.MCNWallet?.getAddress(provider.mcn.primary.june)}
            </span>
            <span className="addressPreview">
              {key.MCNWallet?.getAddress(provider.mcn.primary.jvm)}
            </span>
          </span>
        </div>
      ))}
      <Button
        onClick={handleAddKeyOrMnemonic}
        icon={<GrAdd />}
        style="transparent"
        className="addButton"
        width={30}
        height={30}
      />
    </div>
  );
};

export default MultiSigInput;
