import "./Button.scss";

import React, { ReactNode } from "react";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";

type ButtonProps = {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  style?: "blue" | "white" | "transparent" | "line" | "square" | "undefined";
  className?: string;
  label?: string;
  icon?: ReactNode;
  isLoading?: boolean;
  height?: number | string;
  width?: number | string;
  margin?: string;
  padding?: string;
  disabled?: boolean;
  title?: string;
  children?: ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  label,
  icon,
  type = "button",
  style = "blue",
  isLoading,
  height,
  width,
  margin,
  padding,
  disabled = false,
  title,
  children,
}) => {
  const handleClick = () => {
    if (isLoading) {
      toast.error("Please wait for the current action to finish");
      return;
    }
    if (onClick) onClick();
  };
  return (
    <button
      onClick={handleClick}
      className={`${className ?? ""} styledButton ${style}`}
      type={type}
      style={{
        height: height,
        minHeight: height,
        width: width,
        margin: margin,
        padding: padding,
      }}
      disabled={disabled}
      title={title}
    >
      {isLoading ? (
        <FaSpinner className="loaderButton" />
      ) : (
        <>
          {label}
          {icon}
        </>
      )}
      {children}
    </button>
  );
};

export default Button;
