import React, { memo } from "react";

import { breakpoints } from "../../config/Breakpoints";
import useWindowWidth from "../../hooks/useWindowWidth";
import DesktopNavbar from "./DesktopNavbar/DesktopNavbar";
import MobileNavbar from "./MobileNavbar/MobileNavbar";

export type NavItemType = {
  href?: string;
  link?: string;
  text: string;
  icon: React.ReactNode;
  className: string;
};

interface NavbarProps {
  navItems: NavItemType[];
  children?: React.ReactNode;
  mobileChildren?: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({
  navItems,
  children,
  mobileChildren,
}) => {
  const { windowWidth } = useWindowWidth();

  return (
    <>
      {windowWidth > breakpoints["breakpoint-lg-4"] ? (
        <DesktopNavbar navItems={navItems}>{children}</DesktopNavbar>
      ) : (
        <MobileNavbar navItems={navItems}>{mobileChildren}</MobileNavbar>
      )}
    </>
  );
};

export default memo(Navbar);
