import "./WalletSetup.scss";

import React from "react";
import { Link } from "react-router-dom";

import FooterOnboarding from "../FooterOnboarding/FooterOnboarding";
import Root from "../Utils/Root/Root";

const WalletSetup = () => {
  return (
    <Root>
      <div className="walletSetupDiv" role="main" aria-label="Site Content">
        <div className="walletSetupTitle">
          <h1>
            Introducing the MCN wallet for exploring possibilities of the Juneo
            Multi-Chain Platform
          </h1>
          <span>
            A non-custodial wallet, your keys–your crypto. Unlock the power of
            cross-chain transfers. Send, receive and seamless staking in one
            app. The web application for your on-chain activities.
          </span>
        </div>
        <div className="walletSetupActions">
          <div className="walletSetupRestore">
            <h2>Already have a wallet!</h2>
            <h3>Import your existing seed phrase.</h3>

            <Link
              className="walletSetupButton styledButton blue"
              to="/AccessWallet"
            >
              Access Wallet
            </Link>
            {/* <div className="overlay"></div> */}
          </div>
          <div className="separator"></div>
          <div className="walletSetupGenerate">
            <h2>New to MCN Wallet?</h2>
            <h3>Create a new wallet to send, receive and stake your assets.</h3>

            <Link
              className="walletSetupButton styledButton blue"
              to="/CreateWallet"
            >
              Create a New Wallet
            </Link>
            {/* <div className="overlay"></div> */}
          </div>
        </div>
      </div>
      <FooterOnboarding />
    </Root>
  );
};

export default WalletSetup;
