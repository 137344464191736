import "./CreateWallet.scss";

import { MCNProvider, MCNWallet } from "juneojs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowBack } from "../../../assets/svg/svgIcons";
import { Button } from "../../../mcn-react-ui";
import useAccountStore from "../../../stores/accountStore";
import FooterOnboarding from "../FooterOnboarding/FooterOnboarding";
import Root from "../Utils/Root/Root";
import SelectWrapper, {
  SelectOption,
} from "../Utils/SelectWrapper/SelectWrapper";
import CreateWalletVerify from "./CreateWalletVerify/CreateWalletVerify";
import MnemonicGrid from "./MnemonicGrid/MnemonicGrid";

const CreateWallet = () => {
  const { setMasterWallet, masterWallet } = useAccountStore();

  const [checkedSave, setCheckedSave] = useState(false);
  const [newMnemonic, setNewMnemonic] = useState<string | undefined>();
  const [masterWalletToSave, setMasterWalletToSave] = useState<MCNWallet>();
  const [showModal, setShowModal] = useState(false);

  const options: SelectOption[] = [
    { value: "12 Words mnemonic phrase", label: "12 Words mnemonic phrase" },
    { value: "24 Words mnemonic phrase", label: "24 Words mnemonic phrase" },
  ];

  const history = useNavigate();
  const onBack = () => {
    history("/");
  };

  useEffect(() => {
    if (masterWallet === undefined) {
      const masterWallet: MCNWallet = MCNWallet.generate();
      setNewMnemonic(masterWallet.mnemonic);
      setMasterWalletToSave(masterWallet);
    }
  }, []);

  const saveWallet = () => {
    setCheckedSave(!checkedSave);
  };

  const handleSaveWalletClick = () => {
    if (!checkedSave) return;
    setShowModal(true);
  };

  const handleChangeMode = (value: string) => {
    if (value === "12 Words mnemonic phrase") {
      const masterWallet: MCNWallet = MCNWallet.generate();
      setNewMnemonic(masterWallet.mnemonic);
      setMasterWalletToSave(masterWallet);
    } else if (value === "24 Words mnemonic phrase") {
      const provider = new MCNProvider();
      const hrp: string = provider.mcn.hrp;
      const masterWallet: MCNWallet = MCNWallet.generate(24, hrp);
      setNewMnemonic(masterWallet.mnemonic);
      setMasterWalletToSave(masterWallet);
    }
  };

  return (
    <Root>
      <div className="createWalletPage" role="main" aria-label="Site Content">
        <div className="createWalletTitle">
          {!showModal ? (
            <>
              <h1>This is your key phrase</h1>
              <span>
                Choose how many words your mnemonic phrase should contain, then
                write it down.
              </span>
            </>
          ) : (
            <h1>Verify your mnemonic phrase</h1>
          )}
        </div>
        {!showModal && (
          <div className="createWalletPageDiv">
            <div className="overlay"></div>

            <div onClick={onBack} className="backArrow">
              <ArrowBack />
            </div>
            <SelectWrapper options={options} onChange={handleChangeMode} />
            {newMnemonic && <MnemonicGrid mnemonicPhrase={newMnemonic} />}

            <label className="checkBoxLabel">
              <input
                type="checkbox"
                id="checkBoxLabel"
                checked={checkedSave}
                onChange={() => saveWallet()}
              />
              <span className="checkmark"></span>
              <span className="checkBoxSpan">
                I wrote down my key phrase in a secure location.
              </span>
            </label>

            <Button
              onClick={handleSaveWalletClick}
              disabled={!checkedSave}
              label="Create Wallet"
              className="buttonCreateWalletPageDiv"
              width={"auto"}
              height={47}
              padding="14px 25px"
            />
          </div>
        )}
        {newMnemonic && showModal && (
          <CreateWalletVerify
            setShowModal={setShowModal}
            newMnemonic={newMnemonic}
            masterWalletToSave={masterWalletToSave}
            setMasterWallet={setMasterWallet}
          />
        )}
      </div>
      <FooterOnboarding />
    </Root>
  );
};

export default CreateWallet;
