/**
 * AccountContext provides a React context for managing a Multi-Chain Network (MCN) account.
 * It includes the MCNAccount object from the "juneojs" library for handling account operations
 * like balance fetching, transaction signing, etc.
 */

import { Blockchain, MCNAccount, MCNVault, MCNWallet } from "juneojs";
import React, { createContext, useContext, useEffect, useState } from "react";

import useAccountStore from "../stores/accountStore";
import useNetworkStore from "../stores/networkStore";
import useTokensStore from "../stores/tokensStore";

/**
 * @interface AccountContextType
 * @property {MCNAccount} mcnAccount - The MCNAccount object used for managing account operations.
 */
interface AccountContextType {
  mcnAccount: MCNAccount;
  setMcnAccount: (account: MCNAccount) => void;
  mcnVault: MCNVault;
  masterWallet: MCNWallet[];
}

// Create a React context for AccountContextType, initially set to undefined.
const AccountContext = createContext<AccountContextType | undefined>(undefined);

/**
 * @interface AccountProviderProps
 * @property {MCNWallet} masterWallet[] - The master wallet array object containing account keys and metadata.
 * @property {React.ReactNode} children - React child components that will have access to this context.
 */
interface AccountProviderProps {
  masterWallet: MCNWallet[];
  children: React.ReactNode;
}

/**
 * AccountProvider is a React functional component that sets up the AccountContext.Provider.
 *
 * @param {React.ReactNode} children - React child components that will have access to this context.
 * @param {MCNWallet} masterWallet - The master wallet object containing account keys and metadata.
 * @returns {ReactElement} - Returns AccountContext.Provider React element.
 */
export const AccountProvider: React.FC<AccountProviderProps> = ({
  children,
  masterWallet,
}) => {
  // Initialize MCNProvider and MCNAccount objects.
  const { provider, chainDict } = useNetworkStore();
  const { tokenList } = useTokensStore();
  const { setBlockchainSelect, blockchainSelect } = useAccountStore();

  const [mcnAccount, setMcnAccount] = useState<MCNAccount>(
    new MCNAccount(provider, masterWallet[0]),
  );

  const [mcnVault, setMcnVault] = useState<MCNVault>(
    new MCNVault(provider, masterWallet[0], masterWallet.slice(1)),
  );

  // 1. Set the MCNAccount object using the provider updated with the master wallet.
  useEffect(() => {
    setMcnAccount(new MCNAccount(provider, mcnAccount.wallet));
  }, [tokenList]);

  // 2. Set the MCNVault object using the MCNAccount object.
  useEffect(() => {
    const mainWallet = mcnAccount.wallet;
    const wallets = masterWallet.filter(
      (wallet) => wallet.mnemonic !== mainWallet.mnemonic,
    );
    setMcnVault(new MCNVault(provider, mainWallet, wallets));

    if (!provider.mcn.primary.chains.includes(blockchainSelect)) {
      setBlockchainSelect(provider.mcn.primary.june);
    }
  }, [mcnAccount]);

  // 3. Set the blockchainSelect object using the provider updated with the master wallet.
  useEffect(() => {
    const id = localStorage.getItem("chain") || provider.mcn.primary.june.id;
    const chain = chainDict.find((c: Blockchain) => c.id === id);
    if (chain) {
      setBlockchainSelect(chain);
    }
  }, []);

  return (
    <AccountContext.Provider
      value={{
        mcnAccount,
        setMcnAccount,
        mcnVault,
        masterWallet,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

/**
 * useAccountContext is a React custom hook that provides a convenient way to access AccountContext.
 *
 * @returns {AccountContextType} - Returns the context containing the MCNAccount object.
 * @throws {Error} - Throws an error if used outside of AccountProvider.
 */
export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccountContext must be used within a AccountProvider");
  }
  return context;
};
