import { Blockchain, GenesisNetwork, MCNProvider } from "juneojs";
import { create } from "zustand";

import NetworkType from "../types/Network";

interface NetworkState {
  network: NetworkType;
  setNetwork: (network: NetworkType) => void;

  networksList: NetworkType[];

  // provider for all app.
  // this provider is linked to the current network.
  provider: MCNProvider;
  setProvider: (provider: MCNProvider) => void;

  // state about available blockchain in the current network
  chainDict: Blockchain[];
  setChainDict: (chainDict: Blockchain[]) => void;

  // state for staking config
  MAX_TOTAL_STAKE: bigint;
  setMAX_TOTAL_STAKE: (MAX_TOTAL_STAKE: bigint) => void;

  MIN_TOTAL_STAKE: bigint;
  setMIN_TOTAL_STAKE: (MIN_TOTAL_STAKE: bigint) => void;

  MIN_DELEGATION_STAKE: bigint;
  setMIN_DELEGATION_STAKE: (MIN_DELEGATION_STAKE: bigint) => void;

  MIN_STAKE_DURATION: number;
  setMIN_STAKE_DURATION: (MIN_STAKE_DURATION: number) => void;

  MAX_STAKE_DURATION: number;
  setMAX_STAKE_DURATION: (MAX_STAKE_DURATION: number) => void;
}
const provider = new MCNProvider(GenesisNetwork);
const useNetworkStore = create<NetworkState>((set) => ({
  network: {
    name: "Genesis Testnet",
    rpc: "https://api.socotra-test.network:9650",
    api: "https://genesis.mcnscan.io",
    explorer: "https://genesis.mcnscan.io",
  },
  setNetwork: (network) => set({ network }),

  networksList: [
    {
      name: "Genesis Testnet",
      rpc: "https://api.socotra-test.network:9650",
      api: "https://genesis.mcnscan.io",
      explorer: "https://genesis.mcnscan.io",
    },
  ],

  // provider
  provider: provider,
  setProvider: (provider) => set({ provider }),

  // chainDict
  chainDict: provider.mcn.primary.chains,
  setChainDict: (chainDict) => set({ chainDict }),

  // state for staking config
  MAX_TOTAL_STAKE: BigInt(provider.platformChain.stakeConfig.maxValidatorStake),
  setMAX_TOTAL_STAKE: (MAX_TOTAL_STAKE) => set({ MAX_TOTAL_STAKE }),

  MIN_TOTAL_STAKE: BigInt(provider.platformChain.stakeConfig.minValidatorStake),
  setMIN_TOTAL_STAKE: (MIN_TOTAL_STAKE) => set({ MIN_TOTAL_STAKE }),

  MIN_DELEGATION_STAKE: BigInt(
    provider.platformChain.stakeConfig.minDelegatorStake,
  ),
  setMIN_DELEGATION_STAKE: (MIN_DELEGATION_STAKE) =>
    set({ MIN_DELEGATION_STAKE }),

  MIN_STAKE_DURATION: Number(
    provider.platformChain.stakeConfig.minStakeDuration / 3600n / 24n,
  ),
  setMIN_STAKE_DURATION: (MIN_STAKE_DURATION) => set({ MIN_STAKE_DURATION }),

  MAX_STAKE_DURATION: Number(
    provider.platformChain.stakeConfig.maxStakeDuration / 3600n / 24n,
  ),
  setMAX_STAKE_DURATION: (MAX_STAKE_DURATION) => set({ MAX_STAKE_DURATION }),
}));

export default useNetworkStore;
