import "./FooterOnboarding.scss";

import React from "react";
import { Link } from "react-router-dom";

const FooterOnboarding: React.FC = () => {
  return (
    <div className="termsOfUseWrapper">
      <div className="termsOfUse">
        By using this application you agree to the{" "}
        <Link to="/TermsOfUses">Terms of Use</Link> and{" "}
        <Link to="/PrivacyPolicy">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default FooterOnboarding;
